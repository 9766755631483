import { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { getHideWelcomePopup } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { ClickEventLabel, ClickEventType } from '@jsmdg/tracking';
import { PureLink, useClickOutside } from '@jsmdg/yoshi';
import { trackCustomerAccount } from '../../helper/trackCutomerAccount';
import { type HeaderContext } from '../../types';
import styles from './CustomerAccountFlyout.module.scss';

const messages = defineMessages({
    login: { defaultMessage: 'Anmelden' },
    register: { defaultMessage: 'Neues Konto erstellen' },
    overview: { defaultMessage: 'Überblick' },
    logout: { defaultMessage: 'Abmelden' },
});

type CustomerAccountFlyoutProps = {
    readonly onRequestClose: () => void;
    readonly mouseEnter: () => void;
    readonly onClickLogout: () => void;
};

const handleAccountClick = async (
    label: ClickEventLabel,
    href: string,
    text: string,
    message: string,
) => {
    await trackCustomerAccount(ClickEventType.TEXT_CLICK, label, href, text, message);
    if (label === ClickEventLabel.LOGIN || label === ClickEventLabel.LOGOUT) {
        getHideWelcomePopup().remove();
    }
};

const CustomerAccountFlyout = ({
    mouseEnter,
    onClickLogout,
    onRequestClose,
}: CustomerAccountFlyoutProps): JSX.Element => {
    const { isLoggedIn } = useFragmentContext<HeaderContext>();
    const node = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    useClickOutside(node, onRequestClose);

    return (
        <div
            data-testid="customer-account-flyout"
            role="none"
            className={classNames('mt-1x position-absolute end-0 d-grid', styles.wrapper)}
            ref={node}
            onMouseEnter={mouseEnter}
            onMouseLeave={onRequestClose}
        >
            <PureLink
                href={isLoggedIn ? '/account' : '/login'}
                className={classNames('p-1-5x', styles.link)}
                data-no-instant={isLoggedIn}
                onClick={async event =>
                    handleAccountClick(
                        isLoggedIn ? ClickEventLabel.OVERVIEW : ClickEventLabel.LOGIN,
                        event.currentTarget.href,
                        isLoggedIn ? 'Overview account' : 'Login',
                        isLoggedIn ? 'Überblick' : 'Anmelden',
                    )
                }
                internal
            >
                {intl.formatMessage(isLoggedIn ? messages.overview : messages.login)}
            </PureLink>

            {isLoggedIn ? (
                <PureLink
                    href="/logout"
                    className={classNames('p-1-5x', styles.link)}
                    data-no-instant
                    onClick={async event => {
                        handleAccountClick(
                            ClickEventLabel.LOGOUT,
                            event.currentTarget.href,
                            'Logout account',
                            'Abmelden',
                        );
                        onClickLogout();
                    }}
                    internal
                >
                    {intl.formatMessage(messages.logout)}
                </PureLink>
            ) : (
                <PureLink
                    href="/login"
                    className={classNames('p-1-5x', styles.link)}
                    data-no-instant={false}
                    onClick={async event =>
                        handleAccountClick(
                            ClickEventLabel.REGISTRATION,
                            event.currentTarget.href,
                            'Sign up',
                            'Neues Konto erstellen',
                        )
                    }
                    internal
                >
                    {intl.formatMessage(messages.register)}
                </PureLink>
            )}
        </div>
    );
};

export { CustomerAccountFlyout };
// @loadable/component has an issue with the named export which we couldn't resolve so far.
// When using named exports it fails on the second render
// Using a default export for now until we found solution.
export default CustomerAccountFlyout; // eslint-disable-line import/no-default-export
